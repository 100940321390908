<script setup>
import DataGridControl from 'o365.controls.DataGrid.ts';
import DataColumns from 'o365.controls.DataGrid.DataColumns.ts';

import OGridMenu from 'o365.vue.components.DataGrid.GridMenu.vue';
import ODataGridStatusBar from 'o365.vue.components.DataGrid.StatusBar.vue';
import ODataGridHeader from 'o365.vue.components.DataGrid.Header.vue';
import ODataGridFooter from 'o365.vue.components.DataGrid.Footer.vue';
import ODataGridDataList from 'o365.vue.components.DataGrid.DataList.vue';
import { Overlay, OContentEditable } from 'o365.vue.components.js';
import OContainer from 'o365.vue.components.Container.vue';
import { parseColumnsFromVNodes } from 'o365.vue.components.DataGrid.Column.js'; // Column config parser
import useVirtualScroll from 'o365.vue.composables.VirtualScroll.js';
import useDataGridNavigation from 'o365.vue.composables.DataGridNavigation.js';
import {dataGridControlKey, dataGridIdKey, dataGridIdGroupByKey} from 'o365.modules.vue.injectionKeys.js';
import { ref, computed, watch, onMounted, useSlots, useAttrs, reactive, toRef, h, nextTick, inject, Teleport, resolveComponent, defineAsyncComponent, provide } from 'vue';
import { ODataGridBodyCell } from 'o365.vue.components.DataGrid.helpers.jsx';
import HeaderGroupByContainer from 'o365.vue.components.DataGrid.Header.GroupByContainer.ts.vue';

import useBulkRecords from 'o365.vue.composables.bulkRecords.js'; 

//-----------------------------------------------------------
// INPUT EDITORS
// TODO: Have better way to inlcude input editors inside grid // check
//-----------------------------------------------------------
import { OTextEditor, ONumberEditor, OBitEditor, ODateEditor } from 'o365.vue.components.inputEditors.jsx';

const InputEditors = { OTextEditor, ONumberEditor, OBitEditor, ODateEditor, OContentEditable };
//-----------------------------------------------------------

const props = defineProps({
    dataObject: Object,
    data: Array,
    columns: Array,
    noSelectColumn: Boolean,
    noHeader: Boolean,
    filterRow: {
        type: Boolean,
        default: true,
    },
    rowclickhandler: Function,
    hideMultiselectColumn: Boolean,
    hideActionColumn: Boolean,
    noActiveRows: Boolean,
    rowClass: [Function, Object, String],
    rowStyle: [Function, Object, String],
    hideGridMenu: {
        type: Boolean,
        default: false,
    },
    collapseGridMenu: {
        type: Boolean,
        default: false,
    },
    disableNavigation: Boolean,
    fieldFilters: Array,
    treeColumnDefinition: Object,
    groupByOptions: Object,
    specialGrid: Boolean,
    groupBy: Boolean,
    groupColumnDefinition: Object,
});

const emit = defineEmits(['mounted']);

const slots = useSlots();
const attrs = useAttrs()


//--------------------------------------------------------------------------------------------
// INNER HELPER COMPONENTS
//--------------------------------------------------------------------------------------------

const ODataGridCellEditor = {
    name: 'ODataGridCellEditor',
    props: ['data', 'dataColumns', 'activeCell', 'gridContainer'],
    setup(props, context) {
        const resolveComponentFromApp = inject('resolveComponent');

        const colIndex = computed(() => {
            return parseInt(props.activeCell.split('-')[0]);
        });
        const rowIndex = computed(() => {
            return parseInt(props.activeCell.split('-')[1]);
        });

        const column = computed(() => {
            return props.dataColumns.columns[colIndex.value];
        });

        const row = computed(() => {
            return props.data[rowIndex.value];
        })

        const style = computed(() => {
            const rowIndex = parseInt(props.activeCell.split('-')[1]);

            const pos = rowIndex * 34;

            return {
                'position': 'absolute',
                'width': column.value.width + 'px',
                'left': column.value.left + 'px',
                'height': '34px',
                'transform': `translateY(${pos}px)`,
            }
        });

        const editor = computed(() => {
            if (column.value.cellEditorSlot) {
                return { node: column.value.cellEditorSlot, isSlot: true };
            } else {
                if (typeof column.value.cellEditor === 'string') {
                    let editor = InputEditors[column.value.cellEditor];
                    let node = editor ?? resolveComponentFromApp(column.value.cellEditor) ?? resolveComponent(column.value.cellEditor);
                    return { node: node, isSlot: false };
                } else {
                    return { node: column.value.cellEditor, isSlot: false };
                }
            }
        });

        function getContainer(pinned) {
            if (pinned) {
                return props.gridContainer.querySelector('.o365-body-left-pinned-cols');
            } else {
                return props.gridContainer.querySelector('.o365-body-center-cols-container');
            }
        }

        const editorProps = computed(() => {
            if (typeof editor.value === 'object') {
                if (Array.isArray(editor.value.node.props)) {
                    return editor.value.node.props;
                } else {
                    return Object.keys(editor.value.node.props);
                }
            } else {
                return [];
            }
        });

        return () => h(Teleport, { to: getContainer(column.value.pinned) }, h('div', { 'class': 'o365-body-cell o365-editor-cell o365-cell-range-single-cell', 'style': style.value },
            editor.value.isSlot
                ? h(editor.value.node, {
                    modelValue: row.value,
                    row: row.value,
                    column: column.value,
                    ref: 'editorRef',
                    ...(column.value.cellEditorParams ?? {})
                })
                : h(editor.value.node, {
                    modelValue: row.value[column.value.field],
                    'onUpdate:modelValue': (value) => { row.value[column.value.field] = value; },
                    row: editorProps.value.includes('row') ? row.value : null,
                    column: editorProps.value.includes('column') ? column.value : null,
                    ref: 'editorRef',
                    ...(column.value.cellEditorParams ?? {})
                })
        ));
    }
};
//--------------------------------------------------------------------------------------------

//--- DATA ---
const dataColumns = ref(null);
const activeEditCell = ref(null);
const activeCell = ref(null);
const scrollerWidth = ref(500);
const editMode = ref(false);

const gridMenu = ref(null);

const showWidthScrollbar = ref(false);


if (props.columns) {
    dataColumns.value = new DataColumns(props.columns, props.dataObject, {
        initialColumnsOptions: {
            hideMultiSelectColumn: props.hideMultiselectColumn,
            hideActionColumn: props.hideActionColumn,
        }
    });
} else {
    if (slots.default) {
        // Parse columns from slot
        const vnodes = slots.default();
        const parsedColumns = parseColumnsFromVNodes(vnodes);
        dataColumns.value = new DataColumns(parsedColumns, props.dataObject, {
            initialColumnsOptions: {
                hideMultiSelectColumn: props.hideMultiselectColumn,
                hideActionColumn: props.hideActionColumn,
            }
        });
    } else {
        if (props.dataObject) {
            dataColumns.value = DataColumns.fromDataObject(props.dataObject, {
                initialColumnsOptions: {
                    hideMultiSelectColumn: props.hideMultiselectColumn,
                    hideActionColumn: props.hideActionColumn,
                }
            });
        }
    }
}

const masterGridId = inject(dataGridIdKey, null);

const dataGridControl = ref(new DataGridControl({
    id: attrs.id,
    dataObject: props.dataObject,
    columns: dataColumns.value,
    showSelectColumn: !props.showSelectColumn,
    proxyConstructor: reactive,
    masterGridId: masterGridId?.value,
    fieldFilters: props.fieldFilters,
    treeColumnDefinition: props.treeColumnDefinition,
    disableTreeListener: !!props.groupByOptions,
    groupColumnDefinition: props.groupColumnDefinition
}));

provide(dataGridControlKey, dataGridControl);

provide(dataGridIdKey, dataGridControl.value.id);


watch(dataColumns.value, (a, b) => { dataGridControl.value.watchColumnChanges(a, b) });

watch(() => props.dataObject?.grouping?.enabled, (groupingEnabled) => {
    if (groupingEnabled) {
        dataGridControl.value.initGroupColumn();
    } else {
        dataGridControl.value.disableGroupColumn();
    }
});

const showSummaryRow = ref(!dataColumns.value.columns.every(col => !col.aggregate));

//--- DOM REFERENCES ---
const containerRef = ref(null);
const viewportRef = ref(null);
const cellEditorRef = ref(null);

//--- COMPUTED ---
const data = computed(() => {
    if (props.dataObject) {
        return props.dataObject.data;
    } else if (props.data) {
        return props.data;
    } else {
        return [];
    }
});

const viewPortWidth = computed(() => {
    setViewPortWidth();
    return scrollerWidth.value;
});

const containerHasHeader = computed(() => {
    return slots.cardheader !== undefined;
})

const computedRowClass = computed(() => {
    switch (typeof props.rowClass) {
        case 'function':
            return props.rowClass;
        case 'object':
        case 'string':
            return () => props.rowClass;
        default:
            return () => '';
    }
});
const computedRowStyle = computed(() => {
    switch (typeof props.rowStyle) {
        case 'function':
            return props.rowStyle;
        case 'object':
        case 'string':
            return () => props.rowStyle;
        default:
            return () => '';
    }
});

//--- COMPOSABLES ---

const { selection, syncScrollDataWithNavigation, navigationApi } = props.disableNavigation || props.specialGrid
    ? { selection: null, syncScrollDataWithNavigation: null, navigationApi: null }
    : useDataGridNavigation({
        viewportRef: viewportRef,
        containerRef, containerRef,
        cellEditorRef: cellEditorRef,
        dataGridControl: dataGridControl,
        activeCell: activeCell,
        activeEditCell: activeEditCell,
        dataColumns, dataColumns,
        dataObject: toRef(props, 'dataObject'),
        editMode: editMode,
    });
if (!props.disableNavigation) { dataGridControl.value.initializeCellSelectControl(selection); }

const { scrollData, handleScroll, updateData } = useVirtualScroll({
    dataRef: props.dataObject.paging?.data ?? props.dataObject.data,
    itemSize: 34,
    elementRef: viewportRef,
    recycleList: true,
    paging: props.dataObject.paging??null
});

//--- FUNCTIONS ---
function setViewPortWidth() {
    if (containerRef && containerRef.value) {
        if (!containerRef.value.querySelector('.o365-grid-body')) { return; }
        scrollerWidth.value = containerRef.value.querySelector('.o365-grid-body').clientWidth - dataGridControl.value.dataColumns.leftPinnedWidth - dataGridControl.value.dataColumns.rightPinnedWidth - 16//To do remove this if scroller not visible;

        const viewport = containerRef.value.querySelector('.o365-body-center-cols-container');
        const widthScrollbarIsShown = showWidthScrollbar.value;
        showWidthScrollbar.value = scrollerWidth.value < viewport.scrollWidth;
        if (widthScrollbarIsShown !== showWidthScrollbar.value) {
            containerRef.value?.querySelectorAll('.o365-grid-container').forEach(container => {
                if (!showWidthScrollbar.value && container) {
                    container.style.transform = 'translate(0px)';
                }
            });
        }
        //dataGridControl.value?.setColumnAutoWidths();
    }
}

onMounted(() => {
    dataGridControl.value.initializeContainer(containerRef.value);
    window.setTimeout(() => {
        setViewPortWidth();
    }, 60);

    window.addEventListener('resize', () => {
        setViewPortWidth();
    });

    //setScrollData();
    emit('mounted');

    const modal = containerRef.value.closest('.modal');
    if (modal) {
        modal.addEventListener('shown.bs.modal', () => {
            setViewPortWidth();
        });
    }
    const tabPane = containerRef.value.closest('.tab-pane');
    if (tabPane && tabPane.id) {
        const tabEl = document.querySelector(`[data-bs-target="#${tabPane.id}"]`)
        tabEl?.addEventListener('shown.bs.tab', () => {
            setViewPortWidth();
        })
    }
  
    /*
    props.dataObject.on('BeforeDataLoad', () => {
        isLoading.value = true;
        nextTick().then(() => {
            if (!containerRef.value) { return; }
            const top = containerRef.value.querySelector('.o365-body-center-viewport')?.scrollTop ?? 0;
            containerRef.value.querySelector('.o365-body-center-viewport > .overlay').style.top = `${top}px`;
        });
    });
    props.dataObject.on('DataLoaded', () => {
        isLoading.value = false;
    });
    isLoading.value = dataGridControl.value.dataObject.state._isLoading;
    */

    viewportRef.value.addEventListener('click', (e) => {
        const target = e.target;
        const closest = target.closest('.o365-body-row');
        const rowIndex = closest?.getAttribute('aria-rowindex');
        const row = dataGridControl.value.dataObject.data[rowIndex];
        if (row) {
            if (props.rowclickhandler) {
                props.rowclickhandler(row, e);
            } else {
                dataGridControl.value.setCurrentIndex(row.index ?? row._index);
            }
        }
    });

});

const dataLength = computed(() => {
    return props.dataObject.rowCount;
});

//----------------------------------------------------------------------------------------------
// BULK RECORDS 
//----------------------------------------------------------------------------------------------

const bulkRecords = useBulkRecords(props.dataObject); 
dataGridControl.value.bulkRecords = bulkRecords;

const showNewRecordButton = computed(() => {
    return props.dataObject.allowInsert && bulkRecords.data.length === 0;
});

async function createNewRecord() {
    bulkRecords.createNewRecord();
}

const hasNewRecords = computed(() => {
    return bulkRecords.data.length > 0;
});

//----------------------------------------------------------------------------------------------

defineExpose({ dataColumns, setViewPortWidth, dataGridControl});
</script>

<script>
export default {
    name: 'ODataGrid',
    beforeCreate() {
        const dataColumns = this.$.exposed.dataColumns.value;
        dataColumns.columns.forEach(column => {
            if (typeof column.cellEditor === 'object') {
                if (!this.$options.components.hasOwnProperty(column.cellEditor.name)) {
                    this.$options.components[column.cellEditor.name] = o365.Vue.toRaw(column.cellEditor);
                }       
                column.cellEditor = column.cellEditor.name;
                dataColumns.initialColumns.filter(col => col.field === column.field)[0].cellEditor = column.cellEditor.name;
            }
        });
    }
};
</script>

<template>
    <div class="o365-data-grid o365-root row-container bg-body  "  ref="containerRef" :id="dataGridControl.id">

        <slot v-if="containerHasHeader" name="cardheader" :enable="editMode"></slot>



        <OContainer class="flex-1" :disabled="hideGridMenu">
        

            <div class="o365-grid-body" ref="gridBody">

                <ODataGridHeader v-if="!noHeader" v-model="dataColumns.columns" :data-grid-control="dataGridControl"
                    :filter-row="filterRow" :containerRef="containerRef" :setViewPortWidth="setViewPortWidth" :showNewRecordButton="showNewRecordButton"
                    :createNewRecord="createNewRecord" :hasNewRecords="hasNewRecords" :gridMenu="gridMenu" :hideGridMenu="hideGridMenu" :group-by="groupBy">

                    <template v-if="groupBy" #top>
                        <HeaderGroupByContainer />
                    </template>

                </ODataGridHeader>

                <ODataGridDataList :data="scrollData" :dataGridControl="dataGridControl" :viewportRefFunction="(el)=>{viewportRef = el}" :handle-scroll="handleScroll" :dataLength="dataLength">
                    <template #overlay>
                        <Overlay v-if="dataObject.state.isLoading" />
                    </template>
                    <template #left="{row, rowIndex}">
     
                        <div class="o365-body-row" :aria-rowindex="rowIndex"
                            :class="[{'active':row.item._current && !noActiveRows && !hasNewRecords, 'selected':row.item.isSelected}, computedRowClass(row.item, 'left')]"
                            style="height:34px" :style="[{ 'transform': 'translateY('+row.pos + 'px)' }, computedRowStyle(row.item, 'left')]">
            
                            <template v-for="(col, colIndex) in dataColumns.leftColumns" :key="col.colId">
                                <template v-if="!col.hide && col.pinned === 'left' && col.field !== 'o365_MultiSelect'">
                                    <ODataGridBodyCell :col="col" :colIndex="colIndex" :row="row" :is-active-edit-cell="activeEditCell === colIndex+'-'+row.index" />
                                </template>
                                <template v-if="col.field === 'o365_MultiSelect' && !col.hide">
                                    <div class=" o365-selection-cell" tabindex="-1"
                                        :style="[{'width': col.width + 'px'},{'left': col.left + 'px'}, {'user-select': col.editable ? 'none':''}, ...col.cellStyles]">
                                        <input v-if="!row.item.o_groupHeaderRow" type="checkbox" class="form-check-input p-2" v-model="row.item.isSelected" >
                                    </div>
                                </template>
                                <template v-if="col.field === 'o365_System' && !col.hide">
                                    <div class=" o365-selection-cell pt-1" tabindex="-1" style="user-select: 'none';"
                                        :style="[{'width': col.width + 'px'},{'left': col.left + 'px'}, ...col.cellStyles]">
                                        <div v-if="!row.isLoading" class="spinner-border spinner-border-sm mt-1" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        <i v-else-if="row.item._isError" class="text-danger bi bi-exclamation-triangle-fill"></i>
                                        <i v-else-if="row.item._isNewRecord" class="bi bi-star-fill" @click="dataObject.save(row.item._key)"></i>
                                        <i v-else-if="row.item._isDirty" class="bi bi-pencil-fill" style="cursor: pointer;" :title="$t('Save')" @click="dataObject.save(row.item._key)"></i>
                                        <i v-else-if="row.item._current" class="bi bi-caret-right-fill"></i>
                                    </div>
                                </template>
                            </template>

                        </div>
 
                    </template>
                    <template #center="{row, rowIndex}">

                        <div class="o365-body-row" :aria-rowindex="rowIndex"
                            :class="[{'active':row.item._current && !noActiveRows && !hasNewRecords, 'selected':row.item.isSelected}, computedRowClass(row.item)]"
                            style="height:34px" :style="[{ 'transform': 'translateY('+row.pos + 'px)' }, computedRowStyle(row.item)]">
                            <template v-for="(col, colIndex) in dataColumns.columns" :key="col.colId">
                                <template v-if="!col.hide && !col.pinned">
                                    <ODataGridBodyCell :col="col" :colIndex="colIndex" :row="row" :is-active-edit-cell="activeEditCell === colIndex+'-'+row.index" />
                                </template>
                            </template>
                        </div>

                    </template>

                    <template #right="{row, rowIndex}">

                        <div class="o365-body-row" :aria-rowindex="rowIndex"
                            :class="[{'active':row.item._current && !noActiveRows && !hasNewRecords, 'selected':row.item.isSelected}, computedRowClass(row.item, 'right')]"
                            style="height:34px" :style="[{ 'transform': 'translateY('+row.pos + 'px)' }, computedRowStyle(row.item, 'right')]">
                            <template v-for="(col, colIndex) in dataColumns.rightColumns" :key="colIndex">
                                <template v-if="!col.hide && col.pinned === 'right'">
                                    <ODataGridBodyCell :col="col" :colIndex="col.order" :row="row" :is-active-edit-cell="activeEditCell === colIndex+'-'+row.index" />
                                </template>
                            </template>
                        </div>

                    </template>

                    <template #misc>
                        <ODataGridCellEditor ref="cellEditorRef" v-if="editMode" :data="dataGridControl.dataObject.data" :data-columns="dataColumns" :active-cell="activeCell" :gridContainer="containerRef" />
                    </template>
                </ODataGridDataList>

                <ODataGridFooter v-if="showSummaryRow" :dataGridControl="dataGridControl"/>

                <div v-show="showWidthScrollbar" class="o365-body-horizontal-scroll" style="height: 17px; max-height: 17px; min-height: 17px; width: 100%;">
                     <div class="o365-body-horizontal-scroll-left-spacer" :style="{'min-width': dataColumns.leftPinnedWidth+'px'}"></div>
                     <div class="o365-body-horizontal-scroll-viewport" :style="{'width':viewPortWidth+'px'}">
                         <div class="o365-body-horizontal-scroll-container" style="height: 17px; max-height: 17px; min-height: 17px;"  
                            :style="[{'width':dataColumns.centerWidth + 'px', 'left':dataColumns.leftPinnedWidth+'px'}]"></div>
                     </div>
                     <div class="o365-body-horizontal-scroll-left-spacer" :style="{'min-width': dataColumns.rightPinnedWidth+'px'}"></div>
                </div>

            </div>

            <OGridMenu v-if="!hideGridMenu" :gridRef="dataGridControl" :containerHasHeader="containerHasHeader" ref="gridMenu" :initial-visible="!collapseGridMenu">
                <template #filterBottom>
                    <slot name="setupFilterBottom"></slot>
                </template>
            </OGridMenu>
        </OContainer>

        <ODataGridStatusBar :data-object="dataObject">
            <slot name="statusbar"></slot>
        </ODataGridStatusBar>

    </div>
</template>